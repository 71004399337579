<script>
import OrdersMixin from "@/views/orders/OrdersMixin.vue";
import OrderStopStatus from "@/components/status/OrderStopStatus.vue";
import { OrderFreightState, OrderStopState, OrderStopType } from "@/data/order";
import StopChangesPopover from "@/views/trips/components/StopChangesPopover.vue";
import FacilityInfo from "@/views/trips/components/FacilityInfo.vue";
import AlertMessage from "@/components/AlertMessage.vue";

export default {
  name: "OrderStopBlock",
  components: {
    AlertMessage,
    FacilityInfo,
    StopChangesPopover,
    OrderStopStatus,
  },
  mixins: [OrdersMixin],
  props: {
    orderFreights: {
      type: Array,
    },
    routes: {
      type: Array,
    },
    stop: {
      type: Object,
      required: true,
    },
    disabled: Boolean,
  },
  emits: ["change", "delete"],
  computed: {
    freights() {
      const freights = [];
      for (const freight of this.orderFreights) {
        if (this.stop.freights.includes(freight.id)) {
          freights.push({ ...freight, name: this.getFreightName(freight, freight.number, "", false) });
        }
      }
      return freights;
    },
    isUpdated() {
      return this.stop.state === OrderStopState.updated;
    },
    mismatch() {
      const routeFreights = this.routes.flatMap((r) => r.freights);
      if (!routeFreights || !this.freights || this.stop.type === OrderStopType.delivery) {
        return null;
      }
      const orderRouteFreights = routeFreights.filter((f) => this.freights.map((f) => f.id).includes(f.freightId));
      const allocatedRouteFreightIds = this.routes
        .flatMap((r) => r.routeStops)
        .filter((s) => s.type === OrderStopType.delivery)
        .flatMap((s) => s.freights);
      const mismatchFreights = orderRouteFreights.filter((f) => !allocatedRouteFreightIds.includes(f.id));

      if (mismatchFreights.length === 0) {
        return null;
      }
      const chunks = [];

      const quantityDiff = mismatchFreights.reduce((acc, f) => acc + parseInt(f.quantity), 0);
      chunks.push(
        quantityDiff < 0 ? `selected additional ${quantityDiff} pallets` : `not selected ${quantityDiff} pallets`,
      );
      const weightDiff = mismatchFreights.reduce((acc, f) => acc + parseInt(f.weight), 0);
      chunks.push(weightDiff < 0 ? `selected additional ${weightDiff} lbs` : `not selected ${weightDiff} lbs`);

      return chunks.join(", ");
    },
    deletedFreights() {
      return this.freights.filter((f) => f.isDeleted);
    },
    newFreights() {
      return this.freights.filter((f) => f.state === OrderFreightState.new);
    },
  },
  methods: {
    getFreightClass(freight) {
      return {
        "freight--new": freight.state === OrderFreightState.new,
        "freight--updated": freight.state === OrderFreightState.updated,
        "freight--deleted": freight.isDeleted,
      };
    },
  },
};
</script>

<template>
  <div :class="{ 'reload-stop': stop.type === STOP_RELOAD, 'updated-stop': isUpdated }" class="trip-stops__block">
    <div :class="disabled ? 'bg-red-lighten-5' : 'bg-grey-lighten-4'" class="trip-stops__block-inner pa-4 rounded">
      <div class="mb-6 trip-stops__block-header">
        <div class="d-flex align-center justify-space-between mb-2">
          <div class="d-flex align-center flex-wrap">
            <v-icon v-if="stop.type === STOP_PICK_UP" class="mr-2" color="green" size="20">
              mdi-package-variant-closed
            </v-icon>
            <v-icon v-if="stop.type === STOP_RELOAD" class="mr-2" color="orange" size="20">
              mdi-package-variant-closed
            </v-icon>
            <v-icon v-if="stop.type === STOP_DELIVERY" class="mr-2" color="red accent-2" size="20">
              mdi-map-marker-radius-outline
            </v-icon>
            <span class="blue-text-grey-darken-1 font-weight-600 mr-3 font-size-12" data-qa="stop-type">
              {{ stop.name }}
            </span>
            <router-link
              v-if="stop.order"
              :to="{ name: 'order-view', params: { id: stop.order.orderId || stop.order.id } }"
              class="font-size-12 font-weight-medium text-primary text-decoration-none"
              target="_blank"
            >
              Order #{{ stop.order.number }}
            </router-link>
            <stop-changes-popover v-if="isUpdated" :stop="stop"></stop-changes-popover>
          </div>
          <div v-if="isUpdated" class="badge bg-warning pa-2 rounded-te rounded-bs font-weight-600">UPDATED</div>
        </div>

        <order-stop-status :stop="stop"></order-stop-status>
      </div>

      <div class="mb-6 trip-stops__block-body">
        <facility-info
          :facility="stop.facility"
          :updated="isUpdated && !!stop.oldFacilityId && !disabled"
          class="mb-5"
        ></facility-info>
        <div v-if="stop.timeType && !stop.isReload" class="d-flex align-center text-grey-darken-1">
          <v-icon class="mr-2" size="20">mdi-clock-outline</v-icon>
          <span class="font-weight-medium" data-qa="stop-time"
            >{{ timeTypesMap[stop.timeType] }}: {{ getStopTime(stop) }}</span
          >
        </div>
      </div>
      <div class="trip-stops__block-footer">
        <div class="d-flex">
          <div class="text-grey-darken-1 mr-2">Freights:</div>
          <div
            :class="disabled ? 'text-error' : 'text-grey-darken-2'"
            class="font-weight-medium"
            data-qa="freight-size"
          >
            <div v-for="freight in freights" :key="freight.id" :class="getFreightClass(freight)">
              {{ freight.name }}
            </div>
          </div>
        </div>
        <alert-message v-if="mismatch" class="mt-3"> Freight mismatch: {{ mismatch }}</alert-message>
        <alert-message v-for="newFreight in newFreights" :key="newFreight.id" class="mt-3">
          Add Freight #{{ newFreight.number }} to any PU in route(s)
        </alert-message>
        <alert-message
          v-for="deletedFreight in deletedFreights"
          :key="deletedFreight.id"
          bg-color="indigo-lighten-5"
          border-color="none"
          class="mt-3"
          color="indigo"
          icon="mdi-information-outline"
          icon-color="indigo"
        >
          Freight #{{ deletedFreight.number }} will be removed after re-dispatch confirmation
        </alert-message>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/style/color";

.trip-stops__block-inner {
  min-height: 290px;
}

.reload-stop .trip-stops__block-inner {
  min-height: 610px;
}

.updated-stop .trip-stops__block-inner {
  border: 1px solid $orange;
}

.badge {
  margin-top: -16px;
  margin-right: -16px;
}

.freight--deleted {
  text-decoration-line: line-through;
  text-decoration-color: $grey-lighter-contrast;
  color: $grey-lighter-contrast !important;

  &:after {
    position: absolute;
    content: "•";
    font-size: 20px;
    text-decoration: none !important;
    color: $grey-lighter-contrast;
  }
}

.freight--updated {
  &:after {
    position: absolute;
    content: "•";
    font-size: 20px;
    text-decoration: none !important;
    color: $orange;
  }
}

.freight--new {
  &:after {
    position: absolute;
    content: "•";
    font-size: 20px;
    text-decoration: none !important;
    color: $green;
  }
}
</style>
