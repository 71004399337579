<script>
import OrdersMixin from "@/views/orders/OrdersMixin.vue";
import RouteStopStatusLabel from "../../components/RouteStopStatus.vue";
import EditStopDialog from "@/views/trips/edit/dialogs/EditStopDialog.vue";
import FacilityInfo from "@/views/trips/components/FacilityInfo.vue";
import { OrderStopType } from "@/data/order";

export default {
  name: "RouteStopBlock",
  components: {
    FacilityInfo,
    EditStopDialog,
    RouteStopStatusLabel,
  },
  mixins: [OrdersMixin],
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
    route: {
      type: Object,
    },
    orderStops: {
      type: Array,
      required: true,
    },
    orderFreights: {
      type: Array,
      required: true,
    },
    disabled: Boolean,
  },
  emits: ["update:modelValue", "delete", "changed:freights"],
  data() {
    return {
      editStopDialog: false,
    };
  },
  computed: {
    stop: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    routeStopFreights() {
      return this.stop.freights
        .map((f) => this.route.freights.find((rf) => rf.id === f))
        .filter((f) => f !== undefined)
        .map((f) => {
          const orderFreight = this.orderFreights.find((of) => of.id === f.freightId);
          return {
            ...f,
            number: orderFreight.number,
            name: this.getFreightName(f, orderFreight.number, "", false),
            orderFreight,
          };
        })
        .sort((a, b) => parseInt(a.number) - parseInt(b.number));
    },
    unusedRouteFreights() {
      return this.route.freights.filter(
        (f) =>
          !this.route.routeStops
            .filter((rs) => rs.type === OrderStopType.delivery)
            .flatMap((s) => s.freights)
            .includes(f.id),
      );
    },
  },
  methods: {
    usageChanged() {
      if (this.stop.use && !this.stop.dateTimeTo) {
        this.showEditDialog();
      }
    },
    showEditDialog() {
      this.editStopDialog = true;
    },
    onStopChanged(stop, freights) {
      this.$emit("changed:freights", freights);
      this.stop = { ...stop };
    },
  },
};
</script>

<template>
  <div :class="{ 'reload-stop': stop.type === STOP_RELOAD }" class="trip-stops__block">
    <div :class="disabled ? 'bg-red-lighten-5' : 'bg-grey-lighten-4'" class="trip-stops__block-inner pa-4 rounded">
      <div class="mb-6 trip-stops__block-header">
        <div class="d-flex align-center justify-space-between mb-2">
          <div class="d-flex align-center flex-wrap">
            <v-checkbox
              v-if="stop.editable"
              v-model="stop.use"
              class="mr-1 ma-0 pa-0"
              color="primary"
              density="compact"
              hide-details
              @change="usageChanged"
            />
            <v-icon v-if="stop.type === STOP_PICK_UP" class="mr-2" color="green" size="20">
              mdi-package-variant-closed
            </v-icon>
            <v-icon v-if="stop.type === STOP_RELOAD" class="mr-2" color="orange" size="20">
              mdi-package-variant-closed
            </v-icon>
            <v-icon v-if="stop.type === STOP_DELIVERY" class="mr-2" color="red accent-2" size="20">
              mdi-map-marker-radius-outline
            </v-icon>
            <span class="blue-text-grey-darken-1 font-weight-600 mr-3 font-size-12" data-qa="stop-type">
              {{ stop.name }}
            </span>
            <router-link
              v-if="stop.order"
              :to="{ name: 'order-view', params: { id: stop.order.orderId || stop.order.id } }"
              class="font-size-12 font-weight-medium text-primary text-decoration-none"
              target="_blank"
            >
              Order #{{ stop.order.number }}
            </router-link>
          </div>
          <div>
            <v-btn
              v-if="stop.type === STOP_RELOAD"
              class="text-red text--accent-2 bg-transparent"
              icon="mdi-delete-outline"
              size="x-small"
              variant="flat"
              @click="$emit('delete')"
            />
            <v-btn
              v-if="stop.use && !disabled"
              class="text-primary bg-transparent"
              icon="mdi-pencil-outline"
              size="x-small"
              variant="flat"
              @click="editStopDialog = true"
            />
          </div>
        </div>
        <div v-if="stop.use">
          <route-stop-status-label :stop="stop"></route-stop-status-label>
        </div>
      </div>

      <div v-if="stop.use" class="mb-6 trip-stops__block-body">
        <facility-info
          :facility="stop.facility"
          :updated="!!stop.oldFacilityId && !disabled"
          class="mb-5"
        ></facility-info>
        <div v-if="stop.timeType && !stop.isReload" class="d-flex align-center text-grey-darken-1">
          <v-icon class="mr-2" size="20">mdi-clock-outline</v-icon>
          <span class="font-weight-medium" data-qa="stop-time"
            >{{ timeTypesMap[stop.timeType] }}: {{ getStopTime(stop) }}</span
          >
        </div>
      </div>
      <div v-if="stop.use" class="trip-stops__block-footer">
        <div class="d-flex">
          <div class="text-grey-darken-1 mr-2">Freight:</div>
          <div
            :class="disabled ? 'text-error' : 'text-grey-darken-2'"
            class="font-weight-medium"
            data-qa="freight-size"
          >
            <div
              v-for="freight in routeStopFreights"
              :key="freight.id"
              :class="{ 'freight--deleted': freight.orderFreight.isDeleted }"
            >
              {{ freight.name }}
            </div>
          </div>
        </div>
      </div>

      <div v-else class="flex-1 trip-stops__block-empty">
        <div class="empty-content height-100 d-flex flex-column align-center justify-center">
          <div class="empty-content__img mb-3 line-height-1">
            <img alt="" src="../../../../assets/images/empty-stop.svg" />
          </div>
          <div class="empty-content__text text-grey subheading">Not used in this route</div>
        </div>
      </div>
    </div>
    <edit-stop-dialog
      v-model="editStopDialog"
      :order-freights="orderFreights"
      :order-stops="orderStops"
      :route-freights="route.freights"
      :stop="stop"
      :unused-route-freights="unusedRouteFreights"
      @changed="onStopChanged"
    />
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/style/color";

.trip-stops__block-inner {
  min-height: 290px;
}

.reload-stop .trip-stops__block-inner {
  min-height: 610px;
}

.badge {
  margin-top: -16px;
  margin-right: -16px;
}

.freight--deleted {
  text-decoration-line: line-through;
  text-decoration-color: $grey-lighter-contrast;
  color: $grey-lighter-contrast !important;

  &:after {
    position: absolute;
    content: "•";
    font-size: 20px;
    text-decoration: none !important;
    color: $grey-lighter-contrast;
  }
}
</style>
