<script>
import OmniDialog from "@/components/OmniDialog.vue";
import DatePicker from "@/components/pickers/DatePicker.vue";
import TimePicker from "@/components/pickers/TimePicker.vue";
import OmniSelect from "@/components/controls/OmniSelect.vue";
import FileUploadingGrid from "@/components/FileUploadingGrid.vue";
import ValidationMixin from "@/mixins/validation.mixin";
import { OrderStopType } from "@/data/order";
import { DateTimeMixin } from "@/mixins/date-time.mixin";
import BaseView from "@/views/BaseView.vue";

export default {
  name: "PickupInfoDialog",
  components: { FileUploadingGrid, OmniSelect, TimePicker, DatePicker, OmniDialog },
  mixins: [ValidationMixin, DateTimeMixin, BaseView],
  props: {
    title: String,
    modelValue: Boolean,
    preload: Boolean,
    readonly: Boolean,
    trip: Object,
    route: Object,
    stop: Object,
  },
  emits: ["update:modelValue", "save"],
  data() {
    return {
      loading: false,
      loadData: {
        bolNumbers: [""],
        actualFreights: [],
        files: [],
        date: null,
        time: null,
        timezone: null,
      },
    };
  },
  computed: {
    showDialog: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    freights() {
      return this.uniqueArray(
        this.route.freights.filter((f) => this.stop.freights.includes(f.id)),
        ["id"],
      );
    },
    stopFreights() {
      return this.stop.freights.map((sf) => this.freights.find((f) => f.id === sf)).filter((f) => f !== undefined);
    },
    actualFreightsQuantity() {
      return this.loadData.actualFreights.reduce((sum, f) => sum + (parseInt(f.quantity) || 0), 0);
    },
    actualFreightsWeight() {
      return this.loadData.actualFreights.reduce((sum, f) => sum + (parseInt(f.weight) || 0), 0);
    },
    actualFreightsQuantityClass() {
      if (this.actualFreightsQuantity > this.freightsQuantity) {
        return "text-error";
      } else if (this.actualFreightsQuantity < this.freightsQuantity) {
        return "text-warning";
      } else {
        return "text-success";
      }
    },
    actualFreightsWeightClass() {
      if (this.actualFreightsWeight > this.freightsWeight) {
        return "text-error";
      } else if (this.actualFreightsWeight < this.freightsWeight) {
        return "text-warning";
      } else {
        return "text-success";
      }
    },
    freightsType() {
      if (this.freights.length === 0) return "";
      return this.getFreightType(this.freights[0]);
    },
    freightsQuantity() {
      return this.freights.reduce((sum, f) => sum + parseInt(f.plannedQuantity), 0);
    },
    freightsWeight() {
      return this.freights.reduce((sum, f) => sum + parseInt(f.plannedWeight), 0);
    },
    canRemoveBolNumber() {
      return this.loadData.bolNumbers.length > 1;
    },
  },
  watch: {
    async showDialog(value) {
      if (value) {
        this.loadData.bolNumbers = this.preload ? Array.from(new Set(this.stopFreights.map((f) => f.bolNumber))) : [""];
        this.loadData.actualFreights = this.stopFreights.map((f) => ({
          ...f,
          weight: f.plannedWeight,
          quantity: f.plannedQuantity,
        }));
        if (this.preload) {
          this.loadData.timezone = this.stop.timezone;
          const date = this.$dayjs(this.stop.loadUnloadTime).tz(this.stop.timezone);
          this.loadData.date = date.format("YYYY-MM-DD");
          this.loadData.time = date.format("HH:mm");

          const filesResp = await this.$api.trips.getTripFiles(this.trip.id);
          if (filesResp.success) {
            this.loadData.files = filesResp.data
              .filter((f) => f.tags.includes("route_stop:" + this.stop.id))
              .map((f) => ({
                ...f,
                previewPath: this.getPreviewPath(f),
                bol: f.tags.find((t) => t.startsWith("bol:"))?.split(":")[1],
              }));
          }
        } else {
          this.loadData.timezone = this.stop.facility.timezone;
          const date = this.$dayjs().tz(this.loadData.timezone);
          this.loadData.date = date.format("YYYY-MM-DD");
          this.loadData.time = date.format("HH:mm");
        }
      }
    },
  },
  methods: {
    addBolNumber() {
      this.loadData.bolNumbers.push("");
    },
    removeBolNumber(index) {
      this.loadData.bolNumbers.splice(index, 1);
    },
    getFreightDeliveryPoint(freight) {
      const facility = this.route.routeStops.find(
        (o) => o.type === OrderStopType.delivery && o.freights.includes(freight.id),
      )?.facility;
      return facility ? facility.addressLine + ", " + facility.address : null;
    },
    getFreightData(freight) {
      return freight.plannedQuantity + " " + this.getFreightType(freight) + ", " + freight.plannedWeight + " lbs";
    },
    getFreightType(freight) {
      if (freight.type === "pl") return "pallets";
      return "pcs";
    },
    async save() {
      const { valid } = await this.$refs.form.validate();
      if (!valid || this.loadData.files.length === 0) return;
      this.loading = true;
      for (const uploadingFile of this.loadData.files) {
        uploadingFile.loading = true;
        const fileResponse = await this.$api.trips.uploadRouteStopFile(
          this.trip.id,
          this.stop.id,
          "bol",
          uploadingFile.file,
          uploadingFile.bol,
        );
        if (fileResponse.success) {
          Object.assign(uploadingFile, fileResponse.data);
        } else {
          uploadingFile.error = true;
        }
        uploadingFile.loading = false;
      }
      const response = await this.$api.trips.loadRouteStop(this.trip.id, this.stop.id, {
        bols: this.loadData.bolNumbers.map((number) => ({
          number,
          files: this.loadData.files.filter((file) => file.bol === number),
        })),
        loadTime: this.dateToUTC(this.loadData.date + " " + this.loadData.time, this.loadData.timezone),
        freights: this.loadData.actualFreights,
      });
      if (response.success) {
        this.$emit("save");
        this.showDialog = false;
      } else {
        this.showSnackbarError("Error load verification at stop");
      }
      this.loading = false;
    },
  },
};
</script>

<template>
  <omni-dialog
    v-model="showDialog"
    :disabled="loading"
    :loading="loading"
    :primary-action-label="readonly ? null : 'Save'"
    :title="title"
    size="xlarge"
    @primary-action:click="save"
  >
    <v-form ref="form">
      <div class="mb-6">
        <div class="font-size-16 font-weight-500 mb-6">BOL info</div>
        <div v-for="(_, i) in loadData.bolNumbers" :key="i" class="mb-4 d-flex">
          <v-text-field
            v-model="loadData.bolNumbers[i]"
            :disabled="readonly"
            :rules="[requiredValidator]"
            class="required"
            color="primary"
            data-qa="bol-number-field"
            density="compact"
            hide-details="auto"
            label="BOL number"
            variant="outlined"
          />
          <v-btn
            v-if="canRemoveBolNumber && !readonly"
            class="ml-2"
            color="error"
            data-qa="remove-bol-button"
            icon="mdi-delete-outline"
            size="small"
            variant="text"
            @click="removeBolNumber(i)"
          ></v-btn>
        </div>
        <v-btn
          v-if="!readonly"
          class="font-size-11 font-weight-600 text-uppercase"
          color="primary"
          data-qa="add-bol-button"
          density="compact"
          prepend-icon="mdi-plus"
          variant="text"
          @click="addBolNumber"
        >
          Add BOL
        </v-btn>
      </div>
      <div class="mb-6">
        <div class="font-size-16 font-weight-500 mb-4">Files<span class="text-error">*</span></div>
        <file-uploading-grid v-model="loadData.files" :disabled="readonly" required>
          <template #footer="{ file }">
            <omni-select
              v-if="file"
              v-model="file.bol"
              :disabled="readonly"
              :items="loadData.bolNumbers"
              :rules="[requiredValidator]"
              class="mt-5"
              data-qa="bol-select-file-zone"
              label="BOL"
              required
            ></omni-select>
          </template>
        </file-uploading-grid>
      </div>
      <div class="mb-6">
        <v-row>
          <v-col lg="8" sm="12">
            <div class="font-size-16 font-weight-500 mb-4">Actual freight</div>
            <v-row v-for="actualFreight in loadData.actualFreights" :key="'actual-' + actualFreight.id">
              <v-col cols="2">
                <v-text-field
                  v-model="actualFreight.quantity"
                  :disabled="readonly"
                  :rules="[requiredValidator, digitsValidator]"
                  class="required"
                  color="primary"
                  data-qa="quantity-field"
                  density="compact"
                  hide-details="auto"
                  label="Quantity"
                  variant="outlined"
                />
              </v-col>
              <v-col cols="2">
                <v-text-field
                  v-model="actualFreight.weight"
                  :disabled="readonly"
                  :rules="[requiredValidator, digitsValidator]"
                  class="required"
                  color="primary"
                  data-qa="total-weight-field"
                  density="compact"
                  hide-details="auto"
                  label="Total weight"
                  variant="outlined"
                />
              </v-col>
              <v-col cols="3">
                <omni-select
                  v-model="actualFreight.bolNumber"
                  :disabled="readonly"
                  :items="loadData.bolNumbers"
                  :rules="[requiredValidator]"
                  data-qa="actual-freight-bol-select"
                  label="BOL"
                  required
                ></omni-select>
              </v-col>
              <v-col cols="2">
                <v-text-field
                  v-model="actualFreight.sealNumber"
                  :disabled="readonly"
                  class="required"
                  color="primary"
                  data-qa="seal-number-field"
                  density="compact"
                  hide-details="auto"
                  label="Seal number"
                  variant="outlined"
                />
              </v-col>
              <v-col cols="3">
                <v-checkbox
                  v-model="actualFreight.differentAddress"
                  :disabled="readonly"
                  color="primary"
                  data-qa="different-address-checkbox"
                  density="compact"
                  hide-details
                  label="Different address"
                />
              </v-col>
            </v-row>
            <v-divider class="my-4"></v-divider>
            <div class="font-weight-500 text-grey-darken-3" data-qa="total-freight">
              Total: <span :class="actualFreightsQuantityClass">{{ actualFreightsQuantity }}</span> {{ freightsType }},
              <span :class="actualFreightsWeightClass">{{ actualFreightsWeight }}</span> lbs
            </div>
          </v-col>
          <v-col lg="4" sm="12">
            <div class="font-size-16 font-weight-500 mb-4">Planned freight</div>
            <v-row v-for="freight in freights" :key="freight.id" style="height: 64px">
              <v-col class="text-grey-darken-3 d-flex align-center" cols="4" data-qa="planned-freight"
                >{{ getFreightData(freight) }}
              </v-col>
              <v-col
                class="font-size-16 text-grey-darken-3 d-flex align-center"
                cols="8"
                data-qa="freight-delivery-address"
              >
                <v-icon class="mr-2" color="error" icon="mdi-map-marker-radius-outline" size="20"></v-icon>
                {{ getFreightDeliveryPoint(freight) }}
              </v-col>
            </v-row>
            <v-divider class="my-4"></v-divider>
            <div class="font-weight-500 text-grey-darken-3" data-qa="total-freight-result">
              Total: {{ freightsQuantity }} {{ freightsType }}, {{ freightsWeight }}
              lbs
            </div>
          </v-col>
        </v-row>
      </div>
      <div>
        <div class="font-size-16 font-weight-500 mb-4">Time</div>
        <v-row>
          <v-col cols="3">
            <omni-select
              v-model="loadData.timezone"
              :disabled="readonly"
              :items="timezonesShortList"
              :rules="[requiredValidator]"
              data-qa="timezone"
              label="Time zone"
              required
            />
          </v-col>
          <v-col cols="6">
            <date-picker v-model="loadData.date" :disabled="readonly" label="Date" required></date-picker>
          </v-col>
          <v-col cols="3">
            <time-picker v-model="loadData.time" :disabled="readonly" label="Time" required></time-picker>
          </v-col>
        </v-row>
      </div>
    </v-form>
  </omni-dialog>
</template>

<style lang="scss" scoped></style>
