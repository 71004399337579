<script>
import FacilitySelect from "@/views/orders/components/FacilitySelect.vue";
import FormBlock from "@/components/form/FormBlock.vue";
import OmniTextarea from "@/components/inputs/Textarea.vue";
import OmniSelect from "@/components/controls/OmniSelect.vue";
import draggable from "vuedraggable";
import OrdersMixin from "@/views/orders/OrdersMixin.vue";
import ValidationMixin from "@/mixins/validation.mixin";
import OrderStopStatus from "@/components/status/OrderStopStatus.vue";
import BaseView from "@/views/BaseView.vue";
import {
  OrderFreightState,
  OrderStopState as StopState,
  OrderStopStatus as StopStatus,
  OrderStopType,
} from "@/data/order";
import OrderStopState from "@/components/status/OrderStopState.vue";
import StopDateRange from "@/views/orders/components/StopDateRange.vue";

export default {
  name: "OrderEditStops",
  components: {
    StopDateRange,
    OrderStopState,
    OrderStopStatus,
    draggable,
    OmniSelect,
    OmniTextarea,
    FormBlock,
    FacilitySelect,
  },
  mixins: [BaseView, OrdersMixin, ValidationMixin],
  props: {
    originalOrder: Object,
    modelValue: Object,
  },
  emits: ["update:modelValue"],
  data() {
    return {
      originalFreights: [],
    };
  },
  computed: {
    StopState() {
      return StopState;
    },
    order: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    stops: {
      get() {
        let pickUpKey = 0,
          deliveryKey = 0;
        return this.order.orderStops.map((orderStop) => {
          let key;
          if (orderStop.type === this.STOP_PICK_UP) {
            key = ++pickUpKey;
          } else if (orderStop.type === this.STOP_DELIVERY) {
            key = ++deliveryKey;
          }
          orderStop.$key = key;
          if (orderStop.time2From || orderStop.time2To) {
            orderStop.$showSecondDate = true;
          }
          return orderStop;
        });
      },
      set(value) {
        this.order.orderStops = value;
      },
    },
    freights() {
      return this.order.freights.map((freight, index) => {
        freight.$number = index + 1;
        freight.$name = this.getFreightName(freight, freight.$number);
        return freight;
      });
    },
    stopsEditable() {
      return !this.order.id;
    },
  },
  watch: {
    freights() {
      if (this.originalFreights.length === 0) {
        this.originalFreights = this.freights.map((f) => ({ ...f }));
      }
      for (const freight of this.freights) {
        const originalFreight = this.originalFreights.find((f) => f.id === freight.id);
        if (originalFreight) {
          freight.state = this.compareFreights(freight, originalFreight)
            ? OrderFreightState.normal
            : OrderFreightState.updated;
        }
      }
    },
  },
  methods: {
    compareFreights(freight1, freight2) {
      return (
        freight1.type === freight2.type &&
        parseInt(freight1.quantity) === parseInt(freight2.quantity) &&
        parseInt(freight1.weight) === parseInt(freight2.weight) &&
        parseInt(freight1.length || "0") === parseInt(freight2.length || "0") &&
        parseInt(freight1.width || "0") === parseInt(freight2.width || "0") &&
        parseInt(freight1.height || "0") === parseInt(freight2.height || "0")
      );
    },
    getStopFreights(stop) {
      return stop.freights.map((freightId) => this.freights.find((f) => f.id === freightId));
    },
    getStopPallets(stop) {
      return this.getStopFreights(stop)
        .filter((f) => f.type === this.freightTypePallets)
        .map((f) => f.quantity)
        .reduce((a, b) => parseInt(a) + parseInt(b), 0);
    },
    getStopPieces(stop) {
      return this.getStopFreights(stop)
        .filter((f) => f.type === this.freightTypePieces)
        .map((f) => f.quantity)
        .reduce((a, b) => parseInt(a) + parseInt(b), 0);
    },
    getStopWeight(stop) {
      return this.getStopFreights(stop)
        .map((f) => f.weight)
        .reduce((a, b) => parseInt(a) + parseInt(b), 0);
    },
    isStopDeletable(stop) {
      return this.stopsEditable && this.stops.filter((s) => s.type === stop.type).length > 1;
    },
    async addFreight(stop) {
      const id = this.generateId();
      const freight = {
        id: id,
        type: this.freightTypePallets,
        quantity: 1,
        weight: 1,
        stackable: false,
        state: OrderFreightState.new,
      };

      this.order.freights.push(freight);
      stop.freights.push(freight.id);
    },
    removeFreight(freight) {
      if (this.order.tripId) {
        freight.isDeleted = true;
      } else {
        const freightIndex = this.order.freights.findIndex((f) => f.id === freight.id);
        this.order.orderStops.forEach((stop) => {
          const stopFreightIndex = stop.freights.indexOf(freight.id);
          if (stopFreightIndex > -1) {
            stop.freights.splice(stopFreightIndex, 1);
          }
        });
        this.order.freights.splice(freightIndex, 1);
      }
    },
    async addStop(stopType) {
      const stop = {
        id: this.generateId(),
        type: stopType,
        freights: [],
        note: "",
        timeType: "f",
        timeFrom: null,
        timeTo: null,
        time2Type: "f",
        time2From: null,
        time2To: null,
      };
      if (stopType === this.STOP_PICK_UP) await this.addFreight(stop);
      this.order.orderStops.push(stop);
    },
    deleteStop(stop) {
      if (this.isStopDeletable(stop) && confirm("Are you sure to delete stop?")) {
        const indexToDelete = this.order.orderStops.findIndex((s) => s.id === stop.id);
        if (indexToDelete > -1) {
          this.order.orderStops.splice(indexToDelete, 1);
        }
      }
    },
    getFreightsForStop(stop) {
      const selectedFreights = this.stops
        .filter((s) => s.type === OrderStopType.delivery && s.id !== stop.id)
        .flatMap((s) => s.freights);
      return this.freights.filter((f) => !selectedFreights.includes(f.id));
    },
    isStopEditable(stop) {
      return !this.order.id || StopStatus.notStarted === stop.status;
    },
    isStopUpdated(stop) {
      if (!this.originalOrder || !this.originalOrder.tripId) return false;
      if (stop.state === StopState.updated) return true;

      const originalStop = this.originalOrder.orderStops.find((s) => s.id === stop.id);
      const freights = this.getStopFreights(stop);
      return (
        originalStop.facilityId !== stop.facilityId ||
        freights.some((f) => f.state !== OrderFreightState.normal || f.isDeleted)
      );
    },
  },
};
</script>

<template>
  <div class="mb-4">
    <div class="d-flex align-center justify-space-between mb-4">
      <div class="font-size-16 text-grey-darken-3 font-weight-medium">Stops</div>
      <div v-if="stopsEditable" class="d-flex align-center">
        <v-btn
          class="text-uppercase text-green font-size-12 font-weight-600 pr-2 pl-2"
          color="green"
          height="28"
          variant="outlined"
          @click="addStop(STOP_PICK_UP)"
        >
          <v-icon class="mr-2" color="green" small> mdi-package-variant-closed</v-icon>
          Add pick up
        </v-btn>

        <v-btn
          class="text-uppercase text-red text--accent-2 font-size-12 font-weight-600 pr-2 pl-2 ml-4"
          color="red-accent-2"
          height="28"
          variant="outlined"
          @click="addStop(STOP_DELIVERY)"
        >
          <v-icon class="mr-2" color="red-accent-2" size="small"> mdi-map-marker-radius-outline</v-icon>
          Add delivery
        </v-btn>
      </div>
    </div>
    <draggable v-model="stops" handle=".drag-handle" item-key="id">
      <template #item="{ element, index }">
        <div>
          <form-block
            :class="{
              'v-input--error': element.$error || element.freights?.some((f) => f.$error) || element.$timeError,
            }"
            class="form-block"
          >
            <div class="d-flex align-center justify-space-between mb-4">
              <div class="d-flex align-center">
                <v-icon v-if="element.type === STOP_PICK_UP" class="mr-2" color="green" size="20">
                  mdi-package-variant-closed
                </v-icon>
                <v-icon v-if="element.type === STOP_DELIVERY" class="mr-2" color="red accent-2" size="20">
                  mdi-map-marker-radius-outline
                </v-icon>
                <span class="font-size-11 mr-1">Stop {{ index + 1 }} - </span>
                <span v-if="element.type === STOP_PICK_UP" class="text-caption font-weight-600">
                  Pick up #{{ element.$key }}</span
                >
                <span v-if="element.type === STOP_DELIVERY" class="text-caption font-weight-600">
                  Delivery #{{ element.$key }}</span
                >
                <order-stop-status v-if="order.id" :stop="element" class="ml-2" hide-edge-statuses></order-stop-status>
                <order-stop-state
                  v-if="order.id"
                  :state="isStopUpdated(element) ? StopState.updated : null"
                  :stop="element"
                  class="ml-2"
                ></order-stop-state>
              </div>
              <div>
                <v-btn
                  v-if="isStopDeletable(element)"
                  class="delete-btn pa-0 mr-2"
                  variant="text"
                  @click="deleteStop(element)"
                >
                  <v-icon class="mr-1" color="red-darken-1" size="20">mdi-delete-outline</v-icon>
                  <span class="text-red-darken-1">DELETE STOP</span>
                </v-btn>
                <v-icon v-if="!order.id" class="drag-handle" color="grey-darken-1" icon="mdi-drag" size="20"></v-icon>
              </div>
            </div>

            <v-row>
              <v-col lg="3" sm="12">
                <facility-select
                  v-model="element.facilityId"
                  :readonly="!isStopEditable(element)"
                  :rules="[requiredValidator]"
                  coordinates-tooltip="Coordinates for calculating loaded miles"
                  editable
                  label="Facility"
                  @update="(f) => (element.timezone = f.timezone)"
                />
              </v-col>
              <v-col lg="9" sm="12">
                <div class="stops-right__top">
                  <stop-date-range
                    v-model="stops[index]"
                    :disabled="!isStopEditable(element)"
                    :error="element.$timeError"
                  ></stop-date-range>
                  <omni-select
                    v-if="element.type === STOP_DELIVERY"
                    v-model="element.freights"
                    :disabled="!isStopEditable(element)"
                    :items="getFreightsForStop(element)"
                    :multiple="true"
                    :rules="[requiredValidator]"
                    class="my-3 required"
                    item-title="$name"
                    item-value="id"
                    label="Freight"
                  />
                  <div v-if="element.type === STOP_PICK_UP">
                    <div class="frights-list pt-2">
                      <div
                        v-for="(freight, freightIndex) in getStopFreights(element)"
                        :key="freight?.id"
                        class="freight-block"
                      >
                        <div class="d-flex align-center freight-header">
                          <div class="text-caption text-grey-darken-1">Freight #{{ freight.$number }}:</div>

                          <v-radio-group
                            v-model="freight.type"
                            :disabled="!isStopEditable(element) || freight.isDeleted"
                            :inline="true"
                            color="primary"
                            hide-details
                          >
                            <v-radio :value="freightTypePallets">
                              <template #label>
                                <span class="text-caption">Pallets</span>
                              </template>
                            </v-radio>
                            <v-radio :value="freightTypePieces">
                              <template #label>
                                <span class="text-caption">Pieces</span>
                              </template>
                            </v-radio>
                          </v-radio-group>

                          <div v-if="freight.$error" class="text-red-lighten-2 ml-2" data-qa="alert-message">
                            {{ freight.$error }}
                          </div>
                          <v-spacer />
                          <v-btn
                            v-if="freightIndex === 0"
                            :disabled="!isStopEditable(element)"
                            class="text-uppercase text-caption text-primary font-weight-600 pr-2 pl-1 add-freight__btn"
                            variant="text"
                            @click="addFreight(element)"
                          >
                            <v-icon class="mr-2" color="primary" small>mdi-plus</v-icon>
                            ADD FREIGHT
                          </v-btn>
                          <v-btn
                            v-if="element.freights.length > 1"
                            :disabled="!isStopEditable(element) || freight.isDeleted"
                            class="text-uppercase text-caption text-red-lighten-1 font-weight-600 pr-2 pl-1 add-freight__btn"
                            variant="text"
                            @click="removeFreight(freight)"
                          >
                            <v-icon class="mr-2" color="red-lighten-1" small>mdi-delete-outline</v-icon>
                            Delete freight
                          </v-btn>
                        </div>
                        <div class="freight-body">
                          <div>
                            <div class="d-flex align-center justify-space-between flex-wrap">
                              <v-row class="align-center medium-padding">
                                <v-col lg="10" md="10" sm="10">
                                  <v-row class="medium-padding">
                                    <v-col lg="4" sm="6">
                                      <v-row class="medium-padding">
                                        <v-col sm="6" xl="6">
                                          <v-text-field
                                            v-model="freight.quantity"
                                            :disabled="!isStopEditable(element) || freight.isDeleted"
                                            :rules="[requiredValidator, positiveNumber]"
                                            class="required"
                                            color="primary"
                                            data-qa="planned_quantity"
                                            density="compact"
                                            hide-details="auto"
                                            label="Quantity"
                                            required
                                            variant="outlined"
                                          />
                                        </v-col>
                                        <v-col sm="6" xl="6">
                                          <v-text-field
                                            v-model="freight.weight"
                                            :disabled="!isStopEditable(element) || freight.isDeleted"
                                            :rules="[requiredValidator, positiveNumber]"
                                            class="required"
                                            color="primary"
                                            data-qa="planned_weight"
                                            density="compact"
                                            hide-details="auto"
                                            label="Total weight"
                                            variant="outlined"
                                          />
                                        </v-col>
                                      </v-row>
                                    </v-col>
                                    <v-col lg="5" sm="6">
                                      <v-row>
                                        <v-col class="dimension-part">
                                          <v-text-field
                                            v-model="freight.length"
                                            :disabled="!isStopEditable(element) || freight.isDeleted"
                                            color="primary"
                                            data-qa="length"
                                            density="compact"
                                            hide-details
                                            label="Length"
                                            variant="outlined"
                                          />
                                        </v-col>
                                        <v-col class="dimension-part">
                                          <v-text-field
                                            v-model="freight.width"
                                            :disabled="!isStopEditable(element) || freight.isDeleted"
                                            color="primary"
                                            data-qa="width"
                                            density="compact"
                                            hide-details
                                            label="Width"
                                            variant="outlined"
                                          />
                                        </v-col>
                                        <v-col>
                                          <v-text-field
                                            v-model="freight.height"
                                            :disabled="!isStopEditable(element) || freight.isDeleted"
                                            color="primary"
                                            data-qa="height"
                                            density="compact"
                                            hide-details
                                            label="Height"
                                            variant="outlined"
                                          />
                                        </v-col>
                                      </v-row>
                                    </v-col>
                                  </v-row>
                                </v-col>
                                <v-col lg="2" md="2" sm="2">
                                  <div class="d-flex align-center justify-end">
                                    <v-checkbox
                                      v-model="freight.stackable"
                                      :disabled="!isStopEditable(element) || freight.isDeleted"
                                      :false-value="true"
                                      :true-value="false"
                                      class="pa-0 ma-0 mr-4 stackable"
                                      color="primary"
                                      data-qa="stackable"
                                      hide-details
                                    >
                                      <template #label>
                                        <span class="text-caption">Not stackable</span>
                                      </template>
                                    </v-checkbox>
                                  </div>
                                </v-col>
                              </v-row>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex mb-5">
                      <div class="mr-2 bg-blue-grey-lighten-5 pt-2 pb-2 pr-3 pl-3 rounded-lg total-block">
                        <div class="text-grey-darken-2 text-caption mr-1">
                          Total quantity:<br />
                          <span v-if="getStopPallets(element) > 0" class="font-weight-medium mr-3">
                            {{ getStopPallets(element) }} pallets
                          </span>
                          <span v-if="getStopPieces(element) > 0" class="font-weight-medium">
                            {{ getStopPieces(element) }} pieces
                          </span>
                        </div>
                      </div>
                      <div class="bg-blue-grey-lighten-5 pt-2 pb-2 pr-3 pl-3 rounded-lg total-block">
                        <div class="text-grey-darken-2 text-caption mr-1">
                          Total weight:
                          <div class="font-weight-medium">{{ getStopWeight(element) }} lbs</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="stops-right__bottom text-area-wrapper">
                  <omni-textarea
                    v-model="element.note"
                    :disabled="!isStopEditable(element)"
                    data-qa="stop-notes"
                    label="Order stop note"
                    rows="3"
                  ></omni-textarea>
                </div>
              </v-col>
            </v-row>
          </form-block>
          <div
            v-if="element.$error"
            class="text-red-lighten-2 mb-8 mt-n2 d-flex align-center"
            data-qa="alert-message-last-pickup"
          >
            <v-icon icon="mdi-alert-octagon-outline mr-2"></v-icon>
            {{ element.$error }}
          </div>
        </div>
      </template>
    </draggable>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/style/color";

.total-block {
  min-width: 187px;
}

.form-block {
  &.v-input--error {
    border: 1px solid $danger;
  }
}

.freight-block {
  border: 1px solid $grey-light;
  border-radius: 8px;
  padding: 8px 16px;
  margin-bottom: 16px;
}

.dimension-part {
  position: relative;

  &:after {
    content: "х";
    position: absolute;
    top: 50%;
    right: -4px;
    transform: translateY(-50%);
  }
}

.stackable {
  :deep(.v-label) {
    white-space: nowrap;
  }
}

.medium-padding {
  margin: -7px -8px;

  & > [class*="col-"],
  & > .col {
    padding: 7px 8px;
  }
}

.height-full__bottom,
.stops-right__bottom {
  .v-input {
    height: 100%;

    .v-input__control {
      height: 100%;
    }
  }
}

.stops-right__bottom {
  .v-input__slot {
    height: 100%;
  }
}
</style>
